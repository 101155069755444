import styled from "styled-components"

import { ReferralHistoryReferredUsers } from "src/components/ReferralProgram/ReferralHistory/ReferralHistoryReferredUsers"
import { ReferralHistoryRewards } from "src/components/ReferralProgram/ReferralHistory/ReferralHistoryRewards"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function ReferralHistory() {
  const { t, langKeys } = useTranslate()

  return (
    <Tabs
      labels={[
        {
          id: "referrals",
          label: t(langKeys.referral_program_referrals_history_title),
          tooltip: t(langKeys.referral_program_referrals_history_tooltip),
        },
        {
          id: "rewards",
          label: t(langKeys.referral_program_rewards_history_title),
          tooltip: t(langKeys.referral_program_rewards_history_tooltip),
        },
      ]}
    >
      <StyledTabPanel>
        <ReferralHistoryReferredUsers />
      </StyledTabPanel>
      <StyledTabPanel>
        <ReferralHistoryRewards />
      </StyledTabPanel>
    </Tabs>
  )
}

const StyledTabPanel = styled(TabPanel)`
  padding-top: ${spacing.L};
`

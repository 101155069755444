import { SVGProps } from "react"
import styled from "styled-components"

import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { ReferralLinkBox } from "src/components/ReferralProgram/ReferralLinkBox"
import { ReferralSharing } from "src/components/ReferralProgram/ReferralSharing/ReferralSharing"
import { useFetchReferralProgramUserInfo } from "src/data/referralProgram/queries/referralProgramQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReferralHistoryEmpty({
  Image,
}: {
  Image: React.FC<SVGProps<SVGSVGElement>>
}) {
  const fetchReferralProgramUserInfo = useFetchReferralProgramUserInfo({
    options: {
      enabled: false,
    },
  })
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={Image}
      title={t(langKeys.referrals_empty_state_title)}
      description={t(langKeys.referrals_empty_state_description)}
      imageSpacing={`-${spacing.XL5}`}
      content={
        <Wrapper>
          <ReferralLinkBox
            code={fetchReferralProgramUserInfo.data?.referral_code ?? ""}
          />
          <OrText textAlign="center">{t(langKeys.or)}</OrText>
          <ReferralSharing
            code={fetchReferralProgramUserInfo.data?.referral_code ?? ""}
          />
        </Wrapper>
      }
    />
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.M};
  width: 100%;
`

const OrText = styled(MText)`
  text-transform: uppercase;
`

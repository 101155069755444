import { SVGProps } from "react"
import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { HREF_MINUT_REFERRAL_FAQ } from "src/constants/hrefs"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { Container } from "src/ui/Container/Container"
import { Divider } from "src/ui/Divider/Divider"
import GiftIcon from "src/ui/icons/gift.svg"
import LinkIcon from "src/ui/icons/link.svg"
import RefundIcon from "src/ui/icons/refund.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

function getSteps(t: TTranslateFunction): {
  icon: React.FC<SVGProps<SVGSVGElement>>
  iconProps?: SVGProps<SVGSVGElement>
  title: string
  description: string
}[] {
  return [
    {
      icon: LinkIcon,
      iconProps: {
        transform: "rotate(-45)",
      },
      title: t(langKeys.referral_program_about_share_title),
      description: t(langKeys.referral_program_about_share_description),
    },
    {
      icon: GiftIcon,
      title: t(langKeys.referral_program_about_gift_title),
      description: t(langKeys.referral_program_about_gift_description),
    },
    {
      icon: RefundIcon,
      title: t(langKeys.referral_program_about_get_title),
      description: t(langKeys.referral_program_about_get_description),
    },
  ]
}

export function ReferralAbout() {
  const { t, langKeys } = useTranslate()

  const steps = getSteps(t)
  return (
    <Container>
      <MCard boxShadow={false} border padding={spacing.XL}>
        <StepsWrapper>
          {steps.map((step) => (
            <div key={step.title}>
              <TopWrapper>
                <IconWrapper>
                  <step.icon {...step.iconProps} />
                </IconWrapper>
                <MText variant="subtitle">{step.title}</MText>
              </TopWrapper>
              <MText color="secondary">{step.description}</MText>
            </div>
          ))}
        </StepsWrapper>
        <StyledDivider />
        <MText variant="bodyS" color="tertiary">
          {t(langKeys.referral_program_about_get_note)}{" "}
          <ExternalLink href={HREF_MINUT_REFERRAL_FAQ}>
            {t(langKeys.referral_program_about_faq_link_label)}
          </ExternalLink>
        </MText>
      </MCard>
    </Container>
  )
}

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  > div:not(:last-child) {
    padding-bottom: ${spacing.M};
    border-bottom: 1px solid ${mColors.divider};
    margin-bottom: ${spacing.M};

    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  @container (${breakpoint.smallUp}) {
    grid-template-columns: 1fr 1fr 1fr;

    > div:not(:last-child) {
      padding-right: ${spacing.M};
      border-right: 1px solid ${mColors.divider};
      margin-right: ${spacing.M};

      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  margin-bottom: ${spacing.M};
`

const IconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: ${spacing.XS2};
  border: 2px solid ${mColors.divider};
  border-radius: 9999px;
`

const StyledDivider = styled(Divider)`
  margin-block: ${spacing.L} ${spacing.XS};
`

import styled from "styled-components"

import { generateReferalLink } from "src/components/ReferralProgram/referralProgramUtils"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { CopyText } from "src/ui/CopyText/CopyText"
import { spacing } from "src/ui/spacing"

export function ReferralLinkBox({
  code,
  onCopy,
}: {
  code: string
  onCopy?: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <LinkWrapper>{generateReferalLink(code)}</LinkWrapper>
      <CopyButtonWrapper>
        <CopyText
          copiedText={t(langKeys.copied)}
          value={generateReferalLink(code)}
          button={
            <MButton variant="minimal" onClick={onCopy}>
              {t(langKeys.copy)}
            </MButton>
          }
        />
      </CopyButtonWrapper>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  background-color: ${mColors.neutral};
  border-radius: 0.75rem;
  overflow: auto;
`

const LinkWrapper = styled.div`
  padding: ${spacing.M};

  flex: 1;
  white-space: nowrap;
  overflow: auto;
  flex-shrink: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const CopyButtonWrapper = styled.div`
  padding: ${spacing.M};
  box-shadow: -16px 0 15px 0 ${mColors.neutral};
`

import { Pager } from "src/components/Pager/Pager"
import ReferralUserReferralsEmptyImage from "src/components/ReferralProgram/ReferralHistory/images/referrals-empty-state.svg"
import { ReferralHistoryEmpty } from "src/components/ReferralProgram/ReferralHistory/ReferralHistoryEmpty"
import { useFetchReferralProgramReferredUsers } from "src/data/referralProgram/queries/referralProgramQueries"
import { TReferralsProgramReferredUsersResponse } from "src/data/referralProgram/types/referralProgramQueryTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockType } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { useSearchParams } from "src/router/useSearchParams"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { ErrorService } from "src/utils/ErrorService"
import { formatDate } from "src/utils/l10n"

export function ReferralHistoryReferredUsers() {
  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      {
        key: "referred_users_offset",
        type: "number",
      },
    ],
  })

  const fetchReferredUsers = useFetchReferralProgramReferredUsers({
    filter: {
      offset: searchParams.referred_users_offset ?? undefined,
    },
  })

  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const headers = [
    <div key="email">{t(langKeys.email)}</div>,
    <div key="status">{t(langKeys.status)}</div>,
  ]

  const rows =
    fetchReferredUsers.data?.referrals.map((referral) => (
      <>
        <MText>{referral.email}</MText>
        <div>
          <div>
            <MBadge
              color={
                referral.referral_status === "confirmed" ? "good" : "warning"
              }
            >
              {getStatusLabel({
                t,
                status: referral.referral_status,
                updatedAt: referral.referral_status_at,
                clockType: user.clock_type,
              })}
            </MBadge>
          </div>
        </div>
      </>
    )) ?? []

  if (
    !fetchReferredUsers.isLoading &&
    fetchReferredUsers.data?.referrals.length === 0
  ) {
    return <ReferralHistoryEmpty Image={ReferralUserReferralsEmptyImage} />
  }

  return (
    <div>
      <GridTable
        header={headers}
        rows={rows}
        mobileRows={rows}
        minWidth={0}
        templateColumns="minmax(300px, 1fr) minmax(300px, 1fr)"
        horizontalScroll
        loading={fetchReferredUsers.isLoading}
      />
      <Pager
        offset={searchParams.referred_users_offset ?? 0}
        setOffset={(offset) => setSearchParams("referred_users_offset", offset)}
        limit={50}
        totalCount={fetchReferredUsers.data?.paging.total_count}
      />
    </div>
  )
}

function getStatusLabel({
  t,
  status,
  updatedAt,
  clockType,
}: {
  t: TTranslateFunction
  status: TReferralsProgramReferredUsersResponse["referrals"][number]["referral_status"]
  updatedAt?: string
  clockType: TClockType
}) {
  if (status === "confirmed") {
    return `${t(langKeys.referral_program_referrals_completed)} ${formatDate({
      date: updatedAt ?? "",
      excludeTime: true,
      clockType,
    })} `
  }

  if (status === "pending") {
    return t(langKeys.referral_pending_subscription_activation)
  }

  ErrorService.captureException(
    new Error(`[REFERRAL PROGRAM] Unknown referral status label: ${status}`)
  )
  return t(langKeys.unknown)
}

import styled from "styled-components"

import { ReferralLinkBox } from "src/components/ReferralProgram/ReferralLinkBox"
import { ReferralSharing } from "src/components/ReferralProgram/ReferralSharing/ReferralSharing"
import { HREF_MINUT_REFERRAL_PROGRAM_TC } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReferralInformation({
  referralCode,
}: {
  referralCode: string
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MCard boxShadow={false} border padding={spacing.XL}>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.referral_program_subtitle)}
      </MText>
      <MiddleWrapper>
        <LinkWrapper>
          <ReferralLinkBox code={referralCode} />
        </LinkWrapper>
        <ReferralSharing code={referralCode} />
      </MiddleWrapper>
      <MText variant="bodyS" color="secondary">
        {t(langKeys.referral_program_terms_conditions_default_compliance)}{" "}
        <ExternalLink href={HREF_MINUT_REFERRAL_PROGRAM_TC}>
          {t(langKeys.referral_program_terms_conditions)}
        </ExternalLink>
      </MText>
    </MCard>
  )
}

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
  margin-bottom: ${spacing.L};
`

const LinkWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`

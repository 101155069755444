import styled from "styled-components"

import { ErrorAlert } from "src/components/Account/BillingPortal/ErrorAlert"
import { ReferralAbout } from "src/components/ReferralProgram/ReferralAbout"
import { ReferralHistory } from "src/components/ReferralProgram/ReferralHistory/ReferralHistory"
import { ReferralInformation } from "src/components/ReferralProgram/ReferralInformation"
import { ReferralOverview } from "src/components/ReferralProgram/ReferralOverview/ReferralOverview"
import { useFetchReferralProgramUserInfo } from "src/data/referralProgram/queries/referralProgramQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MainView } from "src/ui/Layout/MainView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReferralProgram() {
  const fetchReferralProgramUserInfo = useFetchReferralProgramUserInfo()

  const isError = fetchReferralProgramUserInfo.isError
  const isLoading = fetchReferralProgramUserInfo.isLoading

  const { t, langKeys } = useTranslate()

  if (isLoading) {
    return (
      <ReferralProgramLayout>
        <div>
          <MSkeleton width="20ch" />
          <MSkeleton width="30ch" />
          <MSkeleton width="30ch" />
        </div>
      </ReferralProgramLayout>
    )
  }

  if (isError) {
    return (
      <ReferralProgramLayout>
        <ErrorWrapper>
          <ErrorAlert />
        </ErrorWrapper>
      </ReferralProgramLayout>
    )
  }

  return (
    <ReferralProgramLayout>
      <ReferralInformation
        referralCode={fetchReferralProgramUserInfo.data.referral_code}
      />
      <SpacingWrapper>
        <MText variant="heading2" marginBottom={spacing.M}>
          {t(langKeys.referral_program_about_title)}
        </MText>
        <ReferralAbout />
      </SpacingWrapper>

      <SpacingWrapper>
        <MText variant="heading2" marginBottom={spacing.M}>
          {t(langKeys.referral_program_history_title)}
        </MText>
        <ReferralOverview
          pendingReferrals={fetchReferralProgramUserInfo.data.pending_referrals}
          confirmedReferrals={
            fetchReferralProgramUserInfo.data.confirmed_referrals
          }
        />
        <HistoryWrapper>
          <ReferralHistory />
        </HistoryWrapper>
      </SpacingWrapper>
    </ReferralProgramLayout>
  )
}

function ReferralProgramLayout({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()

  return (
    <MainView title={t(langKeys.referral_program_title)}>{children}</MainView>
  )
}

const ErrorWrapper = styled.div`
  margin-top: ${spacing.L};
`

const SpacingWrapper = styled.div`
  margin-top: ${spacing.XL2};
`

const HistoryWrapper = styled.div`
  margin-top: ${spacing.XL};
`

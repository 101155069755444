import { usePostTrackEventWithParams } from "./analyticsQueries"

const referralSharingClickedEvent = "Referral link sharing clicked"
export type TReferralSharingEventTypes =
  | "email"
  | "linkedin"
  | "facebook"
  | "whatsapp"
  | "x"

export function usePostReferralSharingClicked() {
  return usePostTrackEventWithParams<
    typeof referralSharingClickedEvent,
    {
      type: TReferralSharingEventTypes
    }
  >({
    event: referralSharingClickedEvent,
  })
}

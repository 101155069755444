import {
  TReferralsProgramReferredUsersFilter,
  TReferralsProgramRewardsFilter,
} from "src/data/referralProgram/types/referralProgramQueryTypes"

export const referralProgramQueryKeys = {
  all() {
    return ["referralProgram"] as const
  },

  userInfo() {
    return [...this.all(), "userInfo"] as const
  },

  referredUsers(filter?: TReferralsProgramReferredUsersFilter) {
    if (filter) {
      return [...this.all(), "referredUsers", filter] as const
    }

    return [...this.all(), "referredUsers"] as const
  },

  rewards(filter?: TReferralsProgramRewardsFilter) {
    if (filter) {
      return [...this.all(), "rewards", filter] as const
    }

    return [...this.all(), "rewards"] as const
  },
} as const

import { SVGProps, useState } from "react"
import styled from "styled-components"

import { generateReferalLink } from "src/components/ReferralProgram/referralProgramUtils"
import {
  TReferralSharingEventTypes,
  usePostReferralSharingClicked,
} from "src/data/analytics/queries/referralsAnalyticsQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import ChevronRight from "src/ui/icons/chevron-right.svg"
import FacebookIcon from "src/ui/icons/facebook.svg"
import LinkedinIcon from "src/ui/icons/linkedin.svg"
import MessageIcon from "src/ui/icons/message.svg"
import TwitterIcon from "src/ui/icons/twitter-x.svg"
import WhatsappIcon from "src/ui/icons/whatsapp.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

function getShareLinks(t: TTranslateFunction): {
  id: TReferralSharingEventTypes
  icon: React.FC<SVGProps<SVGSVGElement>>
  title: string
  generateLink: (referrerName: string, referralLink: string) => string
}[] {
  return [
    {
      id: "email",
      icon: MessageIcon,
      title: t(langKeys.referral_program_share_email),
      generateLink: (referrerName, referralLink) => {
        const subject = t(langKeys.referral_program_share_email_subject, {
          name: referrerName,
        })

        const body = t(langKeys.referral_program_share_email_body, {
          link: referralLink,
        })

        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      },
    },
    {
      id: "facebook",
      icon: FacebookIcon,
      title: t(langKeys.referral_program_share_facebook),
      generateLink: (_, referralLink) => {
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`
      },
    },
    {
      id: "linkedin",
      icon: LinkedinIcon,
      title: t(langKeys.referral_program_share_linkedin),
      generateLink: (_, referralLink) => {
        return `https://www.linkedin.com/shareArticle/?url=${encodeURIComponent(referralLink)}`
      },
    },
    {
      id: "x",
      icon: TwitterIcon,
      title: t(langKeys.referral_program_share_x),
      generateLink: (_, referralLink) => {
        const text = t(langKeys.referral_program_share_x_text)

        return `http://x.com/share?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(text)}`
      },
    },
    {
      id: "whatsapp",
      icon: WhatsappIcon,
      title: t(langKeys.referral_program_share_whatsapp),
      generateLink: (_, referralLink) => {
        return `https://api.whatsapp.com/send?text=${encodeURIComponent(referralLink)}`
      },
    },
  ]
}

export function ReferralSharing({ code }: { code: string }) {
  const [showShareDialog, setShowShareDialog] = useState(false)

  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MButton
        size="small"
        variant="secondary"
        onClick={() => setShowShareDialog(true)}
      >
        {t(langKeys.referral_program_share_link)}
      </MButton>
      <ReferralShareDialog
        referralLink={generateReferalLink(code)}
        open={showShareDialog}
        onClose={() => setShowShareDialog(false)}
      />
    </div>
  )
}

function ReferralShareDialog({
  referralLink,
  open,
  onClose,
}: {
  referralLink: string
  open: boolean
  onClose: () => void
}) {
  const user = useGetUser()

  const postReferralSharingClicked = usePostReferralSharingClicked()

  const { t, langKeys } = useTranslate()

  const shareLinks = getShareLinks(t)

  return (
    <MDialog
      open={open}
      onClose={onClose}
      title={t(langKeys.referral_program_share_link)}
      description={
        <MText variant="bodyS">
          {t(langKeys.referral_program_terms_conditions_default_compliance)}{" "}
          <ExternalLink href="">
            {t(langKeys.referral_program_terms_conditions)}
          </ExternalLink>
        </MText>
      }
      confirmLabel={t(langKeys.close)}
      onConfirm={onClose}
    >
      {shareLinks.map((link) => (
        <StyledLink
          key={link.id}
          beforeNav={() =>
            postReferralSharingClicked.mutate({
              type: link.id,
            })
          }
          href={link.generateLink(user.fullname, referralLink)}
          showExternalIcon={false}
          underline="never"
        >
          <IconWrapper>
            <link.icon width="100%" />
          </IconWrapper>
          <MText variant="subtitle">{link.title}</MText>
          <ChevronRight width={20} />
        </StyledLink>
      ))}
    </MDialog>
  )
}

const StyledLink = styled(ExternalLink)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${spacing.M};
  align-items: center;
  padding-bottom: ${spacing.M};
  margin-bottom: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
`

const IconWrapper = styled.div`
  display: flex;
  width: 32px;
  aspect-ratio: 1/1;
  padding: ${spacing.XS2};
  border: 2px solid ${mColors.divider};
  border-radius: 9999px;
`

import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReferralOverviewCard({
  title,
  items,
}: {
  title: string
  items: {
    value: React.ReactNode
    label: string
  }[]
}) {
  return (
    <div>
      <MText variant="subtitle" marginBottom={spacing.XS}>
        {title}
      </MText>
      <MCard boxShadow={false} border>
        <Content>
          {items.map((item) => (
            <div key={item.label}>
              <MText variant="heading1" textAlign="center">
                {item.value}
              </MText>
              <MText textAlign="center">{item.label}</MText>
            </div>
          ))}
        </Content>
      </MCard>
    </div>
  )
}

const Content = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    border-right: 1px solid ${mColors.divider};
  }
`

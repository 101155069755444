import styled from "styled-components"

import { ReferralOverviewCard } from "src/components/ReferralProgram/ReferralOverview/ReferralOverviewCard"
import { breakpoint } from "src/constants/breakpoints"
import { useTranslate } from "src/i18n/useTranslate"
import { Container } from "src/ui/Container/Container"

export function ReferralOverview({
  pendingReferrals,
  confirmedReferrals,
}: {
  pendingReferrals: number
  confirmedReferrals: number
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Container>
      <Wrapper>
        <ReferralOverviewCard
          title={t(langKeys.referral_program_referrals)}
          items={[
            {
              label: t(langKeys.referral_program_referrals_pending),
              value: pendingReferrals,
            },
            {
              label: t(langKeys.referral_program_referrals_completed),
              value: confirmedReferrals,
            },
          ]}
        />
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @container (${breakpoint.mediumUp}) {
    grid-template-columns: 1fr 1fr;
  }
`

import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { referralProgramQueryKeys } from "src/data/referralProgram/queries/referralProgramQueryCache"
import {
  TReferralsProgramReferredUsersFilter,
  TReferralsProgramReferredUsersResponse,
  TReferralsProgramRewardsFilter,
  TReferralsProgramRewardsResponse,
  TReferralsProgramUserInfoResponse,
} from "src/data/referralProgram/types/referralProgramQueryTypes"
import { useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchReferralProgramUserInfo(prop?: {
  options: UseQueryOptions<
    TReferralsProgramUserInfoResponse,
    AxiosError,
    TReferralsProgramUserInfoResponse,
    ReturnType<typeof referralProgramQueryKeys.userInfo>
  >
}) {
  async function fetchReferralProgramUserInfo() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/referrals/user_info`
    )
    return response.data
  }

  return useQuery({
    queryKey: referralProgramQueryKeys.userInfo(),
    queryFn: fetchReferralProgramUserInfo,
    ...prop?.options,
  })
}

export function useFetchReferralProgramReferredUsers(props?: {
  filter?: TReferralsProgramReferredUsersFilter
  options?: UseQueryOptions<
    TReferralsProgramReferredUsersResponse,
    AxiosError,
    TReferralsProgramReferredUsersResponse,
    ReturnType<typeof referralProgramQueryKeys.referredUsers>
  >
}) {
  async function fetchReferralProgramReferredUsers() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/referrals/referred_users`,
      {
        params: props?.filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: referralProgramQueryKeys.referredUsers(props?.filter),
    queryFn: fetchReferralProgramReferredUsers,
    ...props?.options,
  })
}

export function useFetchReferralProgramRewards(props?: {
  filter?: TReferralsProgramRewardsFilter
  options?: UseQueryOptions<
    TReferralsProgramRewardsResponse,
    AxiosError,
    TReferralsProgramRewardsResponse,
    ReturnType<typeof referralProgramQueryKeys.rewards>
  >
}) {
  return useMinutQuery({
    queryKey: referralProgramQueryKeys.rewards(props?.filter),
    queryPath: "/referrals/rewards",
    filters: props?.filter,
  })
}
